import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Layer, Box, Button, Stack } from 'grommet';
import { Close } from 'grommet-icons';

import useMobile from './useMobile';

const Toast = ({ children, onClose, isOpen }) => {
  const isMobile = useMobile();
  return (
    <>
      {isOpen && (
        <Layer
          responsive={false}
          position={isMobile ? 'bottom' : 'bottom-right'}
          margin="medium"
          modal={false}
        >
          <Stack anchor="top-right">
            <Box
              background="black"
              width={isMobile ? '100%' : 'medium'}
              pad="medium"
            >
              {children}
            </Box>
            <Button
              icon={<Close color="white" />}
              plain
              onClick={onClose}
              margin={{ top: 'small', right: 'small' }}
            />
          </Stack>
        </Layer>
      )}
    </>
  );
};

Toast.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default memo(Toast);
