import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Heading } from 'grommet';

import Toast from './Toast';
import { closeToast } from '../state/ui/uiSlice';

const ToastsApplication = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.ui.toasts);
  const handleClose = useCallback((id) => {
    dispatch(closeToast({ id }));
  });
  return (
    <Box>
      {toasts.map((x) => (
        <Toast
          key={x.id}
          isOpen={true}
          onClose={() => {
            x.onClose?.();
            handleClose(x.id);
          }}
        >
          <Box gap="small">
            <Heading level={5}>{x.title}</Heading>
            {x.body}
          </Box>
        </Toast>
      ))}
    </Box>
  );
};

export default memo(ToastsApplication);
